<template>
    <div>
        <div class="buy_product_button" v-if="exceeded" @click="showDialog = true" v-tooltip :tooltip="$t('app.zamow_ten_towar')">
            z
        </div>
        <ModalProductBuyComponent v-if="showDialog" @close="showDialog = false">
            <h3>{{ $t('app.zamow_towar') }} {{ $t('products.product_' + productId) }}</h3>
            <form @submit.prevent="submitOrder">
                <label for="quantity">{{ $t('app.ilosc') }}:</label>
                <input type="number" v-model="quantity" min="1" required />
                <button type="submit">{{ $t('app.potwierdz_zamowienie') }}</button>
            </form>
        </ModalProductBuyComponent>
    </div>
</template>

<script>
import ModalProductBuyComponent from './ModalProductBuyComponent.vue';
import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";

export default {
    name: "OrderProductButton",
    components: {
        ModalProductBuyComponent
    },
    props: {
        productId: {
            type: [Number, String],
            required: true
        },
        exceeded: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            showDialog: false,
            quantity: 1
        };
    },
    methods: {
        async submitOrder() {
            try {
                const response = await API.post('orders', {
                    productId: this.productId,
                    quantity: this.quantity
                });
                if (response) {
                    this.showDialog = false;
                    this.store.setMessage(response.data.message);
                }
            } catch (error) {
                console.error("Błąd przy składaniu zamówienia:", error);
            }
        }
    },
    created() {
        this.store = usePiniaStore();
    },
};
</script>

<style scoped>
.buy_product_button {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 16px;
    border: 1px solid;
    border-radius: 10px;
    cursor: pointer;
}

button {
    background-color: #5c8a8a;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

button:hover {
    background-color: #457575;
}
</style>
