import {createApp, ref} from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import { createRouter, createWebHistory } from 'vue-router';
import { createHead } from '@vueuse/head';
// import store from './store/store'; // usuwamy Vuex store
import { createPinia } from 'pinia'; // Dodajemy Pinia
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import RegisterForm from './components/RegisterForm.vue';
import LoginForm from './components/LoginForm.vue';
import ResetPasswordForm from './components/ResetPasswordForm.vue';
import Map from './components/MapGrid.vue';
import Logout from './components/Logout.vue';
import EmailConfirmation from './components/EmailConfirmation.vue';
import CityPage from './components/CityPage.vue';
import ProductionPage from './components/ProductionPage.vue';
import TavernPage from './components/TavernPage.vue';
import StatsPage from './components/StatsPage.vue';
import SettingsPage from './components/SettingsPage.vue';
import FortPage from './components/FortPage.vue';
import GovernorPage from './components/GovernorPage.vue';
import DicePage from './components/DicePage.vue';
import MarketplaceComponent from './components/MarketplaceComponent.vue';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/app.css';

import { io } from 'socket.io-client';
import ShopPage from "@/components/ShopPage.vue";
import ShipPage from "@/components/ShipPage.vue";
import ShipyardPage from "@/components/ShipyardPage.vue";
import AcademyPage from "@/components/AcademyPage.vue";
import TooltipDirective from "@/directives/TooltipDirective";

const routes = [
    { path: '/register', component: RegisterForm, meta: { showFooter: true } },
    { path: '/', component: LoginForm, meta: { showFooter: true } },
    { path: '/reset-password', component: ResetPasswordForm, meta: { showFooter: true } },
    { path: '/map', component: Map },
    { path: '/logout', component: Logout, meta: { showFooter: true } },
    { path: '/confirm-email', component: EmailConfirmation, meta: { showFooter: true } },
    { path: '/city/:tile_id', component: CityPage },
    { path: '/production/:tile_id', component: ProductionPage },
    { path: '/shop/:tile_id', component: ShopPage },
    { path: '/ship/:tile_id', component: ShipPage },
    { path: '/shipyard/:tile_id', component: ShipyardPage },
    { path: '/tavern/:tile_id', component: TavernPage },
    { path: '/academy/:tile_id', component: AcademyPage },
    { path: '/stats', component: StatsPage },
    { path: '/settings', component: SettingsPage },
    { path: '/fort/:tile_id', component: FortPage },
    { path: '/governor/:tile_id', component: GovernorPage },
    { path: '/game/:tile_id', component: DicePage },
    { path: '/offers/:offer_id?', component: MarketplaceComponent },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

const showFooter = ref(false);

router.beforeEach((to, from, next) => {
    // Ustawiamy wartość reaktywną showFooter
    showFooter.value = to.meta.showFooter ?? false;
    next();
});

const app = createApp(App);
app.directive('tooltip', TooltipDirective);
const head = createHead();

// eslint-disable-next-line no-undef
const socket = io(process.env.VUE_APP_SOCKET_URL, {
    transports: ['websocket']
});
socket.on('connect', () => {
    console.log('Połączono z serwerem Socket.IO');
});

// eslint-disable-next-line no-undef
const apiUrl = process.env.VUE_APP_API_URL;

const i18n = createI18n({
    locale: 'pl',
    fallbackLocale: 'en',
    messages: {}
});
app.provide('showFooter', showFooter); // Udostępniamy zmienną showFooter w aplikacji
app.use(i18n);
app.use(head);
app.use(router);

// Inicjalizujemy Pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.config.globalProperties.$socket = socket;
app.config.globalProperties.$apiUrl = apiUrl;

app.mount('#app');
