<script>
import { ref, onMounted, computed, watch } from 'vue';
import { usePiniaStore } from "@/store/store";

export default {
    name: "ProgressBarComponent",
    props: {
        startTime: {
            type: String,
            required: true
        },
        endTime: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const store = usePiniaStore();
        const parseDate = (dateString) => new Date(dateString.replace(' ', 'T')).getTime();

        let animationId = null; // Identyfikator requestAnimationFrame
        const timeLeft = ref(0);
        const isVisible = ref(true);

        const updateTime = () => {
            const endTimeStamp = parseDate(props.endTime);
            const remaining = endTimeStamp - Date.now();
            timeLeft.value = Math.max(remaining, 0);

            if (timeLeft.value > 0) {
                animationId = requestAnimationFrame(updateTime);
            } else {
                isVisible.value = false;
                store.setMessage({
                    class: 'info',
                    description: "Twój statek dopłynął do celu!"
                });
            }
        };

        const progressPercentage = computed(() => {
            const startTimeStamp = parseDate(props.startTime);
            const endTimeStamp = parseDate(props.endTime);
            const totalDuration = endTimeStamp - startTimeStamp;
            return Math.max((timeLeft.value / totalDuration) * 100, 0);
        });

        const formatTime = (milliseconds) => {
            const totalSeconds = Math.floor(milliseconds / 1000);
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            return `${hours.toString().padStart(2, '0')}:${minutes
                .toString()
                .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        };

        const formattedTimeLeft = computed(() => formatTime(timeLeft.value));

        // Obserwator zmian w startTime i endTime
        watch(
            () => [props.startTime, props.endTime],
            () => {
                isVisible.value = true; // Pokaż pasek postępu
                timeLeft.value = Math.max(parseDate(props.endTime) - Date.now(), 0);
                if (animationId) {
                    cancelAnimationFrame(animationId); // Anuluj poprzednią animację
                }
                updateTime(); // Rozpocznij nową animację
            },
            { immediate: true }
        );

        onMounted(() => {
            updateTime();
        });

        return { progressPercentage, formattedTimeLeft, isVisible };
    }
};

</script>

<template>
    <div class="progress-bar-container" v-if="isVisible">
        <div class="progress-bar" :style="{ width: progressPercentage + '%' }"></div>
        <span class="progress-text">{{ formattedTimeLeft }}</span>
    </div>
</template>

<style scoped>
.progress-bar-container {
    position: relative;
    height: 10px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.5s ease;
}

.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    color: #000000;
}

</style>
