<template>
    <div v-if="gunOnBuild" class="factory_component">
        <div>
            <div v-html="text"></div>
            <TimerComponent :endTime="endTime" :oData="oData" :action="timerAction" @endGunBuild="fetchPlayerResourcesAfterBuild"></TimerComponent>
        </div>
    </div>
    <div v-else>
        <div class="factory_component">
            <div v-html="text"></div>
            <div v-for="gunType in gunTypes" :key="gunType.id" class="gun_selection">
                <div class="gun_elem_1_4">
                    <h3>{{ gunType.name }}</h3>
                </div>
                <div class="gun_elem_1_4">
                    <p>
                        {{ $t('app.waga_armaty') }}: {{ gunType.weight }} kg<br>
                        {{ $t('app.koszt_budowy') }}:<br>
                        {{ metalLabel }}: {{ Math.round(calculateMetalCost(gunType) * 100) / 100 }} kg<br>
                        {{ $t('app.drewno') }}: {{ Math.round(calculateWoodCost(gunType) * 100) / 100 }} kg<br>
                        {{ $t('app.czas_budowy') }}: {{ Math.round(calculateBuildTime(gunType) * 100) / 100 }} m<br>
                    </p>
                </div>
                <div class="gun_elem_2_4">
                    <button @click="toggleSelect(gunType)" v-if="this.selectedGunId !== gunType.id">{{ $t('app.wybierz') }}</button>
                    <div v-if="gunType.id === selectedGunId">
                        <div class="form-section">
                            <label for="quantity">Ilość:</label>
                            <input type="number" v-model="quantity" min="1">
                        </div>
                        <div class="building-section">
                            <div class="factory_product_section">
                                <p :class="{ 'error-message': exceededWood }">{{ $t('app.suma_kosztow_drewna') }}: {{ Math.round(calculateTotalWoodCost(gunType) * 100) / 100 }} kg</p>
                                <OrderProductButton
                                    productId="8"
                                    :exceeded="exceededWood"
                                />
                            </div>
                            <div class="factory_product_section">
                                <p :class="{ 'error-message': exceededMetal }">{{ $t('app.suma_kosztow') }} {{ metalLabel }}: {{ Math.round(calculateTotalMetalCost(gunType) * 100) / 100 }} kg</p>
                                <OrderProductButton
                                    :productId="metalId"
                                    :exceeded="exceededMetal"
                                />
                            </div>
                            <p>{{ $t('app.czas_budowy') }}: {{ calculateTotalBuildTime(gunType) }}</p>
                            <button
                                v-tooltip
                                :tooltip="(!canAfford(gunType) ? $t('app.nie_masz_tyle_produktow') : '')"
                                @click="build"
                                :disabled="!canAfford(gunType)"
                                :class="{ 'disabled_element': !canAfford(gunType) }">{{ $t('app.buduj') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from "@/utils/axios";
import TimerComponent from "@/components/TimerComponent.vue";
import {usePiniaStore} from "@/store/store";
import OrderProductButton from "@/components/OrderProductButton.vue";

export default {
    name: "FactoryComponent",
    components: {
        TimerComponent,
        OrderProductButton
    },
    data() {
        return {
            gunTypes: [],
            playerResources: [],
            selectedGunId: null,
            quantity: 1,
            exceededWood: false,
            exceededMetal: false,
            technologyBonuses: {},
            gunOnBuild: false,
            endTime: null,
            timerAction: null,
            text: '',
            oData: {},
            metalLabel: 'Brąz',
            metalId: 18
        };
    },
    methods: {
        canAfford(gunType) {
            if (!this.selectedGunId || !gunType) return false;

            const woodCost = this.calculateWoodCost(gunType);
            const totalWoodCost = woodCost * this.quantity;

            const metalCost = this.calculateMetalCost(gunType);
            const totalMetalCost = metalCost * this.quantity;

            const woodAmount = this.getPlayerResource(8); // ID drewna
            const metalAmount = this.getPlayerResource(this.usesCastIron() ? 17 : 18); // ID żeliwa lub brązu
            this.exceededWood = totalWoodCost > woodAmount;
            this.exceededMetal = totalMetalCost > metalAmount;

            return totalWoodCost <= woodAmount && totalMetalCost <= metalAmount;
        },
        calculateMetalCost(gunType) {
            // Oblicz koszt metalu jako 3/4 wagi armaty
            return gunType.weight * 0.75 * (1 - (this.technologyBonuses.cost_reduction || 0) / 100); // Waga metalu w kg
        },
        calculateWoodCost(gunType) {
            // Oblicz koszt drewna jako 1/4 wagi armaty
            return gunType.weight * 0.25 * (1 - (this.technologyBonuses.cost_reduction || 0) / 100); // Waga drewna w kg
        },
        calculateBuildTime(gunType) {
            // Oblicz czas budowy jako zależny od obrażeń armaty
            return gunType.damage * (1 - (this.technologyBonuses.time_reduction || 0) / 100);
        },
        calculateTotalWoodCost(gunType) {
            // Oblicz całkowity koszt drewna
            return this.calculateWoodCost(gunType) * this.quantity;
        },
        calculateTotalMetalCost(gunType) {
            // Oblicz całkowity koszt metalu
            return this.calculateMetalCost(gunType) * this.quantity;
        },
        calculateTotalBuildTime(gunType) {
            // Oblicz całkowity czas budowy
            return this.formatedTime(this.calculateBuildTime(gunType) * this.quantity * 60);
        },
        build() {
            if (!this.selectedGunId || this.quantity <= 0 || !this.canAfford(this.gunTypes.find(gt => gt.id === this.selectedGunId))) {
                return;
            }

            const gunType = this.gunTypes.find(gt => gt.id === this.selectedGunId);
            if (!gunType) return;
            // Wywołanie API do rozpoczęcia budowy
            API.post("/startBuildArmour", {
                gunId: this.selectedGunId,
                quantity: this.quantity
            })
                .then(response => {
                    // Obsługa odpowiedzi z serwera, np. aktualizacja zasobów gracza
                    console.log("Odpowiedź z serwera:", response.data);
                    this.oData = { id: response.data.productionId };
                    this.selectedGunId = null;
                    this.quantity = 1;
                    this.gunOnBuild = true;
                    this.endTime = response.data.endTime;
                    this.timerAction = response.data.timerAction;
                    this.text = response.data.text;
                    // Zaktualizuj zasoby gracza po zakończeniu budowy
                    this.fetchPlayerResources();
                })
                .catch(error => {
                    console.error("Błąd podczas rozpoczynania budowy:", error);
                });
        },
        getPlayerResource(productId) {
            const resource = this.playerResources.find(res => res.product_id === productId);
            return resource ? resource.quantity : 0;
        },
        toggleSelect(gunType) {
            this.selectedGunId = gunType.id;
        },
        formatedTime(seconds) {
            const days = Math.floor(seconds / (3600 * 24));
            const hours = Math.floor((seconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;
            if (days > 0) {
                return `${days}d ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            } else if (hours > 0) {
                return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            } else if (minutes > 0) {
                return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            } else {
                return `${secs.toString().padStart(2, '0')}s`;
            }
        },
        async fetchPlayerResources() {
            await API.get("/getCityProducts")
                .then(response => {
                    this.playerResources = response.data.resources;
                    const userData = this.store.getUserData;
                    this.store.updateCityProducts(this.playerResources);
                    this.$emit('update-products');
                    console.log('userData', userData);
                    this.technologyBonuses = userData.technologyBonuses;
                    this.updateMetalLabel();
                    if (response.data.endTime) {
                        this.gunOnBuild = true;
                        this.oData = { id: response.data.productionId };
                        this.endTime = response.data.endTime;
                        this.timerAction = response.data.timerAction;
                        this.text = response.data.text;
                    } else {
                        this.text = '';
                    }

                    // Pobierz gunTypes z Vuex za pomocą globalnego obiektu $store
                    this.gunTypes = this.store.getGunTypes.map(gunType => ({
                        ...gunType,
                        isSelected: false,
                        quantity: 1,
                        buildDisabled: false
                    }));
                })
                .catch(error => {
                    console.error("Błąd podczas pobierania surowców gracza:", error);
                });
        },
        fetchPlayerResourcesAfterBuild() {
            this.text = "Armaty wybudowane - dodaliśmy je do sklepu.<br> Jeśli chcesz przenieść je na mury miasta - przejdź ";
            this.gunOnBuild = false;
            setTimeout(() => {
                this.fetchPlayerResources();
            }, 2000);
        },
        usesCastIron() {
            const tech = this.store.getTechnologyTree.find(t => t.id === 1);
            return tech && tech.userTechnologyLevel === 3;
        },
        updateMetalLabel() {
            this.metalLabel = this.usesCastIron() ? this.$t('app.zeliwo') : this.$t('app.braz');
            this.metalId = this.usesCastIron() ? 17 : 18;
        },
        orderProduct(gunType, productName) {
            // Logika zamawiania towaru
            console.log('Zamówienie ! ' + productName + '  dla ' + gunType);
            // Możesz dodać tutaj bardziej zaawansowaną logikę, np. wysłanie żądania do API
        }
    },
    mounted() {
        this.fetchPlayerResources();
    },
    created() {
        this.store = usePiniaStore();
    }
};
</script>

<style scoped>
.factory_component {
    width: 95%;
    margin: 20px auto;
    padding: 20px;
    height: 500px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.form-section {
    margin-bottom: 15px;
}

.building-section {
    margin-top: 20px;
}

.error-message {
    color: red;
}

.gun_selection {
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    height: 240px;
    padding: 10px;
}

.gun_elem_1_4 {
    width: 23%;
}

.gun_elem_2_4 {
    width: 50%;
}

.factory_product_section {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
</style>
